define("ember-accordion/templates/components/accordion-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "itrwf7xh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"toggle\",\"panel\",\"close\"],[[29,\"component\",[\"accordion-toggle\"],[[\"activePanelName\",\"toggle\"],[[24,0,[\"activePanel\"]],[29,\"action\",[[24,0,[]],\"togglePanel\"],null]]]],[29,\"component\",[\"accordion-panel\"],[[\"activePanelName\",\"toggle\",\"register\",\"unregister\"],[[24,0,[\"activePanel\"]],[29,\"action\",[[24,0,[]],\"togglePanel\"],null],[29,\"action\",[[24,0,[]],[25,[\"register\"]],[25,[\"itemId\"]]],null],[29,\"action\",[[24,0,[]],[25,[\"unregister\"]],[25,[\"itemId\"]]],null]]]],[29,\"action\",[[24,0,[]],[25,[\"close\"]],[25,[\"itemId\"]]],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-accordion/templates/components/accordion-item.hbs"
    }
  });

  _exports.default = _default;
});