define("ember-accordion/templates/components/accordion-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c2PNrnp1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"item\",\"expandAll\",\"collapseAll\",\"allExpanded\"],[[29,\"component\",[\"accordion-item\"],[[\"activeItems\",\"toggle\",\"close\",\"register\",\"unregister\"],[[24,0,[\"_activeItems\"]],[29,\"action\",[[24,0,[]],\"toggleItem\"],null],[29,\"action\",[[24,0,[]],\"closeItem\"],null],[29,\"action\",[[24,0,[]],\"register\"],null],[29,\"action\",[[24,0,[]],\"unregister\"],null]]]],[29,\"action\",[[24,0,[]],\"expandAll\"],null],[29,\"action\",[[24,0,[]],\"collapseAll\"],null],[25,[\"allExpanded\"]]]]]]],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-accordion/templates/components/accordion-list.hbs"
    }
  });

  _exports.default = _default;
});