define("ember-accordion/templates/components/accordion-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XMdeB99S",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"liquid-if\",[[25,[\"isActive\"]]],null,{\"statements\":[[0,\"  \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-accordion/templates/components/accordion-panel.hbs"
    }
  });

  _exports.default = _default;
});