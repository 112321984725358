define("ember-toggle/components/x-toggle-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vC+7PgZz",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[4,\"if\",[[24,0,[\"show\"]]],null,{\"statements\":[[0,\"  \"],[8,\"label\"],[13,\"for\",[24,0,[\"for\"]]],[13,\"class\",[30,[\"toggle-text toggle-prefix \",[24,0,[\"labelType\"]]]]],[13,\"onclick\",[29,\"action\",[[24,0,[]],\"clickLabel\"],null]],[14,2],[9],[0,\" \\n\"],[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"      \"],[15,1,[[24,0,[\"label\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[24,0,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-toggle/components/x-toggle-label/template.hbs"
    }
  });

  _exports.default = _default;
});