define("ember-light-table/templates/components/lt-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CeRDtVDm",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[4,\"each\",[[25,[\"columns\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"component\",[[29,\"concat\",[\"light-table/cells/\",[24,1,[\"cellType\"]]],null],[24,1,[]],[25,[\"row\"]]],[[\"table\",\"rawValue\",\"tableActions\",\"extra\"],[[25,[\"table\"]],[29,\"get\",[[25,[\"row\"]],[24,1,[\"valuePath\"]]],null],[25,[\"tableActions\"]],[25,[\"extra\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/lt-row.hbs"
    }
  });

  _exports.default = _default;
});