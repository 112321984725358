define("ember-light-table/templates/components/lt-scaffolding-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QV5FYU+A",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[4,\"each\",[[25,[\"columns\"]]],null,{\"statements\":[[0,\"  \"],[7,\"td\"],[12,\"style\",[29,\"html-safe\",[[29,\"if\",[[24,1,[\"width\"]],[29,\"concat\",[\"width: \",[24,1,[\"width\"]]],null]],null]],null]],[11,\"class\",\"lt-scaffolding\"],[9],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/lt-scaffolding-row.hbs"
    }
  });

  _exports.default = _default;
});