define("ember-toggle/components/x-toggle-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rZIn9FSl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\"],[11,\"class\",\"x-toggle\"],[12,\"checked\",[24,0,[\"toggled\"]]],[12,\"disabled\",[24,0,[\"disabled\"]]],[12,\"id\",[24,0,[\"forId\"]]],[12,\"name\",[24,0,[\"name\"]]],[12,\"onchange\",[29,\"action\",[[24,0,[]],[24,0,[\"sendToggle\"]]],[[\"value\"],[\"target.checked\"]]]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n\\n\"],[7,\"label\"],[12,\"for\",[24,0,[\"effectiveForId\"]]],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"id\",[30,[\"x-toggle-visual-\",[24,0,[\"forId\"]]]]],[11,\"role\",\"checkbox\"],[12,\"class\",[30,[\"x-toggle-btn \",[24,0,[\"themeClass\"]],\" \",[24,0,[\"size\"]],[29,\"if\",[[24,0,[\"disabled\"]],\" x-toggle-disabled\"],null]]]],[12,\"aria-owns\",[24,0,[\"forId\"]]],[12,\"aria-checked\",[24,0,[\"toggled\"]]],[12,\"data-tg-on\",[24,0,[\"onLabel\"]]],[12,\"data-tg-off\",[24,0,[\"offLabel\"]]],[9],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-toggle/components/x-toggle-switch/template.hbs"
    }
  });

  _exports.default = _default;
});