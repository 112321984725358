define("liquid-fire/templates/components/liquid-if", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "upamFZbr",
    "block": "{\"symbols\":[\"container\",\"valueVersion\",\"valueVersion\",\"&inverse\",\"&default\"],\"statements\":[[4,\"if\",[[25,[\"containerless\"]]],null,{\"statements\":[[0,\"\\n\"],[0,\"\\n\"],[4,\"liquid-versions\",null,[[\"value\",\"matchContext\",\"use\",\"rules\",\"renderWhenFalse\",\"class\"],[[29,\"if\",[[25,[\"inverted\"]],[29,\"if\",[[25,[\"predicate\"]],false,true],null],[29,\"if\",[[25,[\"predicate\"]],true,false],null]],null],[29,\"hash\",null,[[\"helperName\"],[[25,[\"helperName\"]]]]],[25,[\"use\"]],[25,[\"rules\"]],[26,4],[25,[\"class\"]]]],{\"statements\":[[4,\"if\",[[24,3,[]]],null,{\"statements\":[[0,\"      \"],[15,5],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[15,4],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[3]},null]],\"parameters\":[]},{\"statements\":[[4,\"liquid-container\",null,[[\"id\",\"class\",\"growDuration\",\"growPixelsPerSecond\",\"growEasing\",\"shrinkDelay\",\"growDelay\",\"enableGrowth\"],[[25,[\"containerId\"]],[25,[\"class\"]],[25,[\"growDuration\"]],[25,[\"growPixelsPerSecond\"]],[25,[\"growEasing\"]],[25,[\"shrinkDelay\"]],[25,[\"growDelay\"]],[25,[\"enableGrowth\"]]]],{\"statements\":[[4,\"liquid-versions\",null,[[\"value\",\"notify\",\"matchContext\",\"use\",\"rules\",\"renderWhenFalse\"],[[29,\"if\",[[25,[\"inverted\"]],[29,\"if\",[[25,[\"predicate\"]],false,true],null],[29,\"if\",[[25,[\"predicate\"]],true,false],null]],null],[24,1,[]],[29,\"hash\",null,[[\"helperName\"],[[25,[\"helperName\"]]]]],[25,[\"use\"]],[25,[\"rules\"]],[26,4]]],{\"statements\":[[4,\"if\",[[24,2,[]]],null,{\"statements\":[[0,\"        \"],[15,5],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[15,4],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "liquid-fire/templates/components/liquid-if.hbs"
    }
  });

  _exports.default = _default;
});