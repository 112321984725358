define('ember-cable/core/subscription', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Subscription = Ember.Object.extend({
    subscriptions: null,
    params: null,

    identifier: Ember.computed('params', function () {
      return JSON.stringify(Ember.get(this, 'params'));
    }),

    init: function init() {
      this._super.apply(this, arguments);
      Ember.get(this, 'subscriptions').add(this);
    },
    perform: function perform(action) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      data.action = action;
      this.send(data);
    },
    send: function send(data) {
      Ember.get(this, 'subscriptions.consumer').send({
        command: 'message',
        identifier: Ember.get(this, 'identifier'),
        data: JSON.stringify(data)
      });
    },
    unsubscribe: function unsubscribe() {
      return Ember.get(this, 'subscriptions').remove(this);
    }
  });

  Subscription.toString = function () {
    return 'Subscription';
  };

  exports.default = Subscription;
});