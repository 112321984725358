define("ember-infinity/templates/components/infinity-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+YJr4G0I",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,0,[\"isVisible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[[24,0,[\"loaderClassNames\"]],[29,\"if\",[[24,0,[\"viewportEntered\"]],\" in-viewport\"],null],[29,\"if\",[[24,0,[\"isDoneLoading\"]],\" reached-infinity\"],null]]]],[11,\"data-test-infinity-loader\",\"\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"      \"],[15,1,[[24,0,[\"infinityModelContent\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,0,[\"isDoneLoading\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\"],[9],[1,[24,0,[\"loadedText\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"span\"],[9],[1,[24,0,[\"loadingText\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n  \"],[3,\"did-insert\",[[24,0,[\"didInsertLoader\"]],[24,0,[]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-infinity/templates/components/infinity-loader.hbs"
    }
  });

  _exports.default = _default;
});