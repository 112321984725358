define("ember-accordion/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.childOf('.AccordionPanel'), this.use('crossFade', {
      duration: 2000
    }), this.reverse('toUp', {
      duration: 2000
    }));
  }
});