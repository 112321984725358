define('ember-attacher/components/attach-tooltip', ['exports', 'ember-attacher/components/attach-popover', 'ember-attacher/defaults'], function (exports, _attachPopover, _defaults) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _attachPopover.default.extend({
    ariaRole: 'tooltip',

    class: Ember.computed({
      get: function get() {
        return this.get('_config').tooltipClass || _defaults.default.tooltipClass;
      },
      set: function set(_key, value) {
        var tooltipClass = this.get('_config').tooltipClass || _defaults.default.tooltipClass;

        return tooltipClass + ' ' + value;
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._currentTarget.setAttribute('aria-describedby', this.id);
    },


    popperTargetChanged: Ember.observer('popperTarget', function () {
      var oldTarget = this._currentTarget;
      if (oldTarget) {
        oldTarget.removeAttribute('aria-describedby');
      }

      this._super.apply(this, arguments);

      this.get('popperTarget').setAttribute('aria-describedby', this.id);
    }),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var target = this._currentTarget;
      if (target) {
        target.removeAttribute('aria-describedby');
      }
    }
  });
});