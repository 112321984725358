define('ember-cable/core/consumer', ['exports', 'ember-cable/core/subscriptions', 'ember-cable/core/connection'], function (exports, _subscriptions, _connection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    url: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.subscriptions = _subscriptions.default.create(Ember.getOwner(this).ownerInjection(), { consumer: this });
      this.connection = _connection.default.create(Ember.getOwner(this).ownerInjection(), { consumer: this });
    },
    send: function send(data) {
      this.connection.send(data);
    },
    willDestroy: function willDestroy() {
      this._super();
      this.connection.destroy();
      this.subscriptions.destroy();
    }
  });
});