define("ember-content-loader/components/content-loader-svg", ["exports", "ember-content-loader/templates/components/content-loader-svg"], function (_exports, _contentLoaderSvg) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _contentLoaderSvg.default,
    tagName: 'svg',
    attributeBindings: ['role', 'rtlStyle:style', 'aria-label', 'viewBox', 'preserveAspectRatio'],
    role: 'img',
    rtlStyle: Ember.computed('rtl', function () {
      return Ember.String.htmlSafe(Ember.get(this, 'rtl') ? 'transform: scaleX(-1)' : '');
    }),
    'aria-label': Ember.computed('ariaLabel', function () {
      return Ember.get(this, 'ariaLabel') || null;
    }),
    viewBox: Ember.computed('width', 'height', function () {
      return Ember.String.htmlSafe("0 0 ".concat(Ember.get(this, 'width'), " ").concat(Ember.get(this, 'height')));
    }),
    rectClipPath: Ember.computed('baseUrl', 'idClip', function () {
      return Ember.String.htmlSafe("url(".concat(Ember.get(this, 'baseUrl'), "#").concat(Ember.get(this, 'idClip'), ")"));
    }),
    rectStyle: Ember.computed('baseUrl', 'idGradient', function () {
      return Ember.String.htmlSafe("fill: url(".concat(Ember.get(this, 'baseUrl'), "#").concat(Ember.get(this, 'idGradient'), ")"));
    }),
    animateValues1: Ember.computed('gradientRatio', function () {
      return Ember.String.htmlSafe("".concat(-Ember.get(this, 'gradientRatio'), "; ").concat(-Ember.get(this, 'gradientRatio'), "; 1"));
    }),
    animateValues2: Ember.computed('gradientRatio', function () {
      var div2 = this.gradientRatio / 2;
      return Ember.String.htmlSafe("".concat(-div2, "; ").concat(-div2, "; ").concat(1 + div2));
    }),
    animateValues3: Ember.computed('gradientRatio', function () {
      return Ember.String.htmlSafe("0; 0; ".concat(1 + Ember.get(this, 'gradientRatio')));
    }),
    idClip: Ember.computed('elementId', function () {
      return Ember.String.htmlSafe("".concat(Ember.get(this, 'elementId'), "-idClip"));
    }),
    idGradient: Ember.computed('elementId', function () {
      return Ember.String.htmlSafe("".concat(Ember.get(this, 'elementId'), "-idGradient"));
    }),
    keyTimes: Ember.computed('interval', function () {
      return "0; ".concat(Ember.get(this, 'interval'), "; 1");
    }),
    dur: Ember.computed('speed', function () {
      return Ember.String.htmlSafe("".concat(Ember.get(this, 'speed'), "s"));
    })
  });

  _exports.default = _default;
});