define("ember-power-select-typeahead/templates/components/power-select-typeahead/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uSvYbnKB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\"],[12,\"value\",[23,\"text\"]],[11,\"role\",\"combobox\"],[12,\"id\",[30,[\"ember-power-select-typeahead-input-\",[25,[\"select\",\"uniqueId\"]]]]],[11,\"class\",\"ember-power-select-typeahead-input ember-power-select-search-input\"],[11,\"autocomplete\",\"off\"],[11,\"autocorrect\",\"off\"],[11,\"autocapitalize\",\"off\"],[11,\"spellcheck\",\"false\"],[12,\"placeholder\",[23,\"placeholder\"]],[12,\"oninput\",[23,\"onInput\"]],[12,\"onfocus\",[23,\"onFocus\"]],[12,\"onblur\",[23,\"onBlur\"]],[12,\"disabled\",[25,[\"select\",\"disabled\"]]],[12,\"onkeydown\",[29,\"action\",[[24,0,[]],\"handleKeydown\"],null]],[12,\"onmousedown\",[29,\"action\",[[24,0,[]],\"stopPropagation\"],null]],[11,\"type\",\"search\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"select\",\"loading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"ember-power-select-typeahead-loading-indicator\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select-typeahead/templates/components/power-select-typeahead/trigger.hbs"
    }
  });

  _exports.default = _default;
});