define("ember-content-loader/templates/components/content-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eqGRmLR2",
    "block": "{\"symbols\":[\"@width\",\"@height\",\"&default\",\"@animate\",\"@ariaLabel\",\"@baseUrl\",\"@gradientRatio\",\"@interval\",\"@preserveAspectRatio\",\"@primaryColor\",\"@primaryOpacity\",\"@rtl\",\"@secondaryColor\",\"@secondaryOpacity\",\"@speed\",\"&attrs\"],\"statements\":[[5,\"content-loader-svg\",[[14,16]],[[\"@animate\",\"@ariaLabel\",\"@baseUrl\",\"@gradientRatio\",\"@height\",\"@interval\",\"@preserveAspectRatio\",\"@primaryColor\",\"@primaryOpacity\",\"@rtl\",\"@secondaryColor\",\"@secondaryOpacity\",\"@speed\",\"@width\"],[[29,\"defaults-to\",[[24,4,[]],true],null],[29,\"defaults-to\",[[24,5,[]],\"Loading interface...\"],null],[29,\"defaults-to\",[[24,6,[]],\"\"],null],[29,\"defaults-to\",[[24,7,[]],2],null],[29,\"defaults-to\",[[24,2,[]],130],null],[29,\"defaults-to\",[[24,8,[]],0.25],null],[29,\"defaults-to\",[[24,9,[]],\"none\"],null],[29,\"defaults-to\",[[24,10,[]],\"#f0f0f0\"],null],[29,\"defaults-to\",[[24,11,[]],1],null],[29,\"defaults-to\",[[24,12,[]],false],null],[29,\"defaults-to\",[[24,13,[]],\"#e0e0e0\"],null],[29,\"defaults-to\",[[24,14,[]],1],null],[29,\"defaults-to\",[[24,15,[]],2],null],[29,\"defaults-to\",[[24,1,[]],400],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[26,3]],null,{\"statements\":[[0,\"    \"],[15,3],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"rect\"],[11,\"x\",\"0\"],[11,\"y\",\"0\"],[11,\"rx\",\"5\"],[11,\"ry\",\"5\"],[12,\"width\",[29,\"defaults-to\",[[24,1,[]],400],null]],[12,\"height\",[29,\"defaults-to\",[[24,2,[]],130],null]],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-content-loader/templates/components/content-loader.hbs"
    }
  });

  _exports.default = _default;
});