define("ember-awesome-macros/array/-utils", ["exports", "ember-macro-helpers/normalize-array-key", "ember-macro-helpers"], function (_exports, _normalizeArrayKey, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalizeArray = normalizeArray;
  _exports.normalizeArray2 = normalizeArray2;
  _exports.normalizeArray3 = normalizeArray3;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var sentinelValue = {};

  function normalizeArrayArgs(keys) {
    keys[0] = (0, _normalizeArrayKey.default)(keys[0]);
  }

  function convertArray(array) {
    if (array instanceof Ember.ArrayProxy) {
      return array;
    }

    if (Array.isArray(array)) {
      // we use .slice() here so that plain arrays are
      // not internally mutated to Ember arrays
      return Ember.A(array.slice());
    }

    if (Ember.isArray(array)) {
      // this is required by the current `concat()` implementation because
      // Ember.Array itself does not define `concat()` so it only works
      // for Ember.Array instances that are backed by a real array
      return Ember.A(array.toArray());
    }

    return null;
  }

  function getDefaultValue(func, identityVal) {
    var val = func();
    return val === sentinelValue ? identityVal : val;
  }

  function normalizeArray(_ref, callback) {
    var _ref$defaultValue = _ref.defaultValue,
        defaultValue = _ref$defaultValue === void 0 ? function () {
      return sentinelValue;
    } : _ref$defaultValue;
    return function () {
      for (var _len = arguments.length, keys = new Array(_len), _key = 0; _key < _len; _key++) {
        keys[_key] = arguments[_key];
      }

      normalizeArrayArgs(keys);
      return _emberMacroHelpers.lazyComputed.apply(void 0, keys.concat([function (get, arrayKey) {
        var arrayVal = get(arrayKey);
        var emberArrayVal = convertArray(arrayVal);

        if (emberArrayVal === null) {
          return getDefaultValue(defaultValue, arrayVal);
        }

        for (var _len2 = arguments.length, args = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
          args[_key2 - 2] = arguments[_key2];
        }

        var values = args.map(get);
        return callback.call.apply(callback, [this, emberArrayVal].concat(_toConsumableArray(values)));
      }]));
    };
  }

  function normalizeArray2(funcStr) {
    var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {
      return sentinelValue;
    };
    return function () {
      for (var _len3 = arguments.length, keys = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        keys[_key3] = arguments[_key3];
      }

      normalizeArrayArgs(keys);
      return _emberMacroHelpers.lazyComputed.apply(void 0, keys.concat([function (get, arrayKey) {
        var arrayVal = get(arrayKey);
        var emberArrayVal = convertArray(arrayVal);

        if (emberArrayVal === null) {
          return getDefaultValue(defaultValue, arrayVal);
        }

        var prop = emberArrayVal[funcStr];

        if (typeof prop === 'function') {
          for (var _len4 = arguments.length, args = new Array(_len4 > 2 ? _len4 - 2 : 0), _key4 = 2; _key4 < _len4; _key4++) {
            args[_key4 - 2] = arguments[_key4];
          }

          return prop.apply(emberArrayVal, args.map(get));
        }

        return prop;
      }]));
    };
  }

  function normalizeArray3(_ref2) {
    var _ref2$firstDefault = _ref2.firstDefault,
        firstDefault = _ref2$firstDefault === void 0 ? function () {
      return sentinelValue;
    } : _ref2$firstDefault,
        _ref2$secondDefault = _ref2.secondDefault,
        secondDefault = _ref2$secondDefault === void 0 ? function () {
      return sentinelValue;
    } : _ref2$secondDefault,
        func = _ref2.func;
    return (0, _emberMacroHelpers.createClassComputed)([false, true], function (array, key) {
      for (var _len5 = arguments.length, args = new Array(_len5 > 2 ? _len5 - 2 : 0), _key5 = 2; _key5 < _len5; _key5++) {
        args[_key5 - 2] = arguments[_key5];
      }

      return _emberMacroHelpers.lazyComputed.apply(void 0, [(0, _normalizeArrayKey.default)(array, [key])].concat(args, [function (get, arrayKey) {
        var arrayVal = get(arrayKey);
        var emberArrayVal = convertArray(arrayVal);

        if (emberArrayVal === null) {
          return getDefaultValue(firstDefault, arrayVal);
        }

        if (typeof key !== 'string') {
          return getDefaultValue(secondDefault, arrayVal);
        }

        for (var _len6 = arguments.length, args = new Array(_len6 > 2 ? _len6 - 2 : 0), _key6 = 2; _key6 < _len6; _key6++) {
          args[_key6 - 2] = arguments[_key6];
        }

        var resolvedArgs = [key].concat(_toConsumableArray(args.map(get)));

        if (typeof func === 'function') {
          return func.apply(void 0, [emberArrayVal].concat(_toConsumableArray(resolvedArgs)));
        }

        return emberArrayVal[func].apply(emberArrayVal, _toConsumableArray(resolvedArgs));
      }]));
    });
  }
});