define("@ember/render-modifiers/modifiers/did-insert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
    The `{{did-insert}}` element modifier is activated when an element is
    inserted into the DOM.
  
    In this example, the `fadeIn` function receives the `div` DOM element as its
    first argument and is executed after the element is inserted into the DOM.
  
    ```handlebars
    <div {{did-insert this.fadeIn}} class="alert">
      {{yield}}
    </div>
    ```
  
    ```js
    export default Component.extend({
      fadeIn(element) {
        element.classList.add('fade-in');
      }
    });
    ```
  
    By default, the executed function will be unbound. If you would like to access
    the component context in your function, use the `action` decorator as follows:
  
    ```handlebars
    <div {{did-insert this.incrementCount}}>first</div>
    <div {{did-insert this.incrementCount}}>second</div>
  
    <p>{{this.count}} elements were rendered</p>
    ```
  
    ```js
    export default Component.extend({
      count: tracked({ value: 0 }),
  
      incrementCount: action(function() {
        this.count++;
      })
    });
    ```
  
    @method did-insert
    @public
  */
  var _default = Ember._setModifierManager(function () {
    return {
      capabilities: Ember._modifierManagerCapabilities('3.13', {
        disableAutoTracking: true
      }),
      createModifier: function createModifier() {},
      installModifier: function installModifier(_state, element, args) {
        var _args$positional = _toArray(args.positional),
            fn = _args$positional[0],
            positional = _args$positional.slice(1);

        fn(element, positional, args.named);
      },
      updateModifier: function updateModifier() {},
      destroyModifier: function destroyModifier() {}
    };
  }, function DidInsertModifier() {
    _classCallCheck(this, DidInsertModifier);
  });

  _exports.default = _default;
});