define("liquid-fire/templates/components/liquid-bind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v174qNea",
    "block": "{\"symbols\":[\"container\",\"version\",\"version\",\"&default\"],\"statements\":[[4,\"if\",[[25,[\"containerless\"]]],null,{\"statements\":[[4,\"liquid-versions\",null,[[\"value\",\"use\",\"rules\",\"matchContext\",\"versionEquality\",\"renderWhenFalse\",\"class\"],[[25,[\"value\"]],[25,[\"use\"]],[25,[\"rules\"]],[25,[\"forwardMatchContext\"]],[25,[\"versionEquality\"]],true,[25,[\"class\"]]]],{\"statements\":[[4,\"if\",[[26,4]],null,{\"statements\":[[15,4,[[24,3,[]]]]],\"parameters\":[]},{\"statements\":[[1,[24,3,[]],false]],\"parameters\":[]}]],\"parameters\":[3]},null]],\"parameters\":[]},{\"statements\":[[4,\"liquid-container\",null,[[\"id\",\"class\",\"growDuration\",\"growPixelsPerSecond\",\"growEasing\",\"shrinkDelay\",\"growDelay\",\"enableGrowth\"],[[25,[\"containerId\"]],[25,[\"class\"]],[25,[\"growDuration\"]],[25,[\"growPixelsPerSecond\"]],[25,[\"growEasing\"]],[25,[\"shrinkDelay\"]],[25,[\"growDelay\"]],[25,[\"enableGrowth\"]]]],{\"statements\":[[4,\"liquid-versions\",null,[[\"value\",\"notify\",\"use\",\"rules\",\"matchContext\",\"versionEquality\",\"renderWhenFalse\"],[[25,[\"value\"]],[24,1,[]],[25,[\"use\"]],[25,[\"rules\"]],[25,[\"forwardMatchContext\"]],[25,[\"versionEquality\"]],true]],{\"statements\":[[4,\"if\",[[26,4]],null,{\"statements\":[[15,4,[[24,2,[]]]]],\"parameters\":[]},{\"statements\":[[1,[24,2,[]],false]],\"parameters\":[]}]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "liquid-fire/templates/components/liquid-bind.hbs"
    }
  });

  _exports.default = _default;
});