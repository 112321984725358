define("ember-accordion/utils/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    id: null,
    panel: null
  });

  _exports.default = _default;
});