define("ember-awesome-macros/hash", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deconstructArgs = deconstructArgs;
  _exports.reduceValues = reduceValues;
  _exports.default = _default;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function deconstructArgs(args) {
    var hashKeys = [];
    var hashValues = [];
    args.forEach(function (arg) {
      if (typeof arg === 'string') {
        hashKeys.push(arg);
        hashValues.push(arg);
      } else {
        var props = Object.getOwnPropertyNames(arg);
        hashKeys = hashKeys.concat(props);
        hashValues = hashValues.concat(props.map(function (prop) {
          return arg[prop];
        }));
      }
    });
    return {
      hashKeys: hashKeys,
      hashValues: hashValues
    };
  }

  function reduceValues(hashKeys, newValues) {
    return newValues.reduce(function (newHash, val, i) {
      newHash[hashKeys[i]] = val;
      return newHash;
    }, {});
  }

  function _default() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var _deconstructArgs = deconstructArgs(args),
        hashKeys = _deconstructArgs.hashKeys,
        hashValues = _deconstructArgs.hashValues;

    return (0, _emberMacroHelpers.curriedComputed)(function () {
      for (var _len2 = arguments.length, newValues = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        newValues[_key2] = arguments[_key2];
      }

      var newHash = reduceValues(hashKeys, newValues);
      return Ember.Object.create(newHash);
    }).apply(void 0, _toConsumableArray(hashValues));
  }
});