define('ember-cable/core/connection_monitor', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ConnectionMonitor = Ember.Object.extend({
    connection: null,
    stoppedAt: null,
    startedAt: null,
    pingedAt: null,
    disconnectedAt: null,
    staleThreshold: 6,
    reconnectAttempts: 0,
    _intervalTimer: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.start();
    },
    start: function start() {
      Ember.set(this, 'reconnectAttempts', 0);
      Ember.set(this, 'stoppedAt', null);
      Ember.set(this, 'startedAt', Date.now());
      this.poll();
    },
    connected: function connected() {
      Ember.set(this, 'reconnectAttempts', 0);
      Ember.set(this, 'pingedAt', Date.now());
      Ember.set(this, 'disconnectedAt', null);
    },
    disconnected: function disconnected() {
      Ember.set(this, 'disconnectedAt', Date.now());
    },
    ping: function ping() {
      Ember.set(this, 'pingedAt', Date.now());
    },
    poll: function poll() {
      var _this = this;

      this._intervalTimer = setTimeout(function () {
        Ember.run(function () {
          _this.reconnectIfStale();
          _this.poll();
        });
      }, this.interval());
    },
    willDestroy: function willDestroy() {
      this._super();
      clearTimeout(this._intervalTimer);
    },
    interval: function interval() {
      return Math.max(3, Math.min(30, 5 * Math.log(Ember.get(this, 'reconnectAttempts') + 1))) * 1000;
    },
    reconnectIfStale: function reconnectIfStale() {
      if (this.connectionIsStale()) {
        this.incrementProperty('reconnectAttempts');
        if (!this.disconnectedRecently()) {
          Ember.get(this, 'connection').reopen();
        }
      }
    },
    connectionIsStale: function connectionIsStale() {
      return !Ember.get(this, 'connection').isConnecting() && this.secondsSince(Ember.get(this, 'pingedAt') || Ember.get(this, 'startedAt')) > Ember.get(this, 'staleThreshold');
    },
    disconnectedRecently: function disconnectedRecently() {
      return Ember.get(this, 'disconnectedAt') && this.secondsSince(Ember.get(this, 'disconnectedAt')) < Ember.get(this, 'staleThreshold');
    },
    secondsSince: function secondsSince(time) {
      return (Date.now() - time) / 1000;
    }
  });

  ConnectionMonitor.toString = function () {
    return 'ConnectionMonitor';
  };

  exports.default = ConnectionMonitor;
});